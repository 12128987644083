<template>
	<!-- <screen-adapter>
		<router-view />
	</screen-adapter> -->
	<router-view />
</template>
<script>
import {
	reactive,
	toRefs,
	defineComponent,
	onMounted,
	onUnmounted
} from 'vue';
import {
	ElConfigProvider
} from 'element-plus';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
export default defineComponent({
	components: {
		[ElConfigProvider.name]: ElConfigProvider,
	},
	setup() {
		let locale = zhCn;
		const state = reactive({});
		// onMounted(() => {
		// 	const faviconLink = document.querySelector('link[rel="icon"]');
		// 	if (faviconLink) {
		// 		faviconLink.href = faviconHref.value;
		// 	}
		// });
		return {
			...toRefs(state),
			locale,
		};
	}
});
</script>
<style>
* {
  margin: 0;
  padding: 0;
  /* font-size: 16px; */
}
.arrowTransform{
    transition: 0.2s;
    transform-origin: center;
    transform: rotateZ(180deg);
}
.arrowTransformReturn{
    transition: 0.2s;
    transform-origin: center;
    transform: rotateZ(0deg);
}
.arrowLanguage{
    transition: 0.2s;
    transform-origin: center;
    transform: rotateZ(90deg);
}
.arrowLanguageReturn{
    transition: 0.2s;
    transform-origin: center;
    transform: rotateZ(0deg);
}
ul {
    list-style: none;
}           
li {
    display: inline;      
}
a {
    list-style: none;
	border: none; 
	text-decoration: none;     
}
.class-scroll::-webkit-scrollbar {
    width: 2px;
}
.class-scroll::-webkit-scrollbar-track {
    border-radius: 5px;
}
.class-scroll::-webkit-scrollbar-thumb {
    border-radius: 5px;
	background-color: #dedede;
}


</style>
<style scoped>

::v-deep .el-input__inner{
    font-size: 18px !important;  
}
::v-deep .el-input--large .el-input__wrapper{
    height: 50px !important;
}
::v-deep .el-dialog__headerbtn{
    padding: 20px 30px !important;
    width: 48px !important;
    height: 48px !important;
    right: 30px;
}
::v-deep .el-dialog{
    border-radius: 10px !important;
    padding: 20px 30px;
}
::v-deep .el-dialog__title{
    font-size: 20px !important;
    font-weight: 900;
}

</style>
