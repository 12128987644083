
export default {
  state() {
    return {
      list: [
        {
          id: 1, itemImg: '', backColor: 'green', backImage: '../../../assets/image/1.jpg', backType: 0,srtFlag: false,fontTextarea: 'nihao1',
          list: [
            {
              id: 1,
              title: '你可以拖着我，按照自己的意愿调整大小。1',
              x: 0, y: 0, 
              w: 100,h: 100,
              angle: 0,
              rotatable: false,
              lockAspectRatio: false,
              z: 1, 
              cardType: 1,
              imageType: {
                isOcclusion: false,
                imageUrl: 'https://ts1.cn.mm.bing.net/th/id/R-C.66d7b796377883a92aad65b283ef1f84?rik=sQ%2fKoYAcr%2bOwsw&riu=http%3a%2f%2fwww.quazero.com%2fuploads%2fallimg%2f140305%2f1-140305131415.jpg&ehk=Hxl%2fQ9pbEiuuybrGWTEPJOhvrFK9C3vyCcWicooXfNE%3d&risl=&pid=ImgRaw&r=0'
              }
                
            },
            {
              id: 2,
              title: '你可以拖着我，按照自己的意愿调整大小。2',
              x: 210, y: 0, w: 100, h: 165,
              angle: 0,
              rotatable: false,
              lockAspectRatio: 'true',
              isOcclusion: false,
              z: 3,
              cardType: 2,
              imageType: {},
              shuzir: {
                backColor: '#ffffff',
                type: 1,
                url: ''
              }
            },
            {
              id: 4,
              title: '你可以拖着我，按照自己的意愿调整大小。3333333333',
              x: 0, y: 0, 
              w: 100,h: 100,
              angle: 0,
              rotatable: false,
              lockAspectRatio: false,
              z: 2, 
              cardType: 1,
              imageType: {
                isOcclusion: true,
                imageUrl: 'https://ts1.cn.mm.bing.net/th/id/R-C.66d7b796377883a92aad65b283ef1f84?rik=sQ%2fKoYAcr%2bOwsw&riu=http%3a%2f%2fwww.quazero.com%2fuploads%2fallimg%2f140305%2f1-140305131415.jpg&ehk=Hxl%2fQ9pbEiuuybrGWTEPJOhvrFK9C3vyCcWicooXfNE%3d&risl=&pid=ImgRaw&r=0'
              }
            },
            {
              id: 3,
              title: '这是文字图层，这是文字图层，这是文字图层',
              x: 420, y: 0, w: 100, h: 100,
              angle: 0,
              rotatable: false,
              lockAspectRatio: false,
              z: 4,
              cardType: 3,
              imageType: {},
              stroke:{
                color: '#000000',
                thickness: 10,
                fontSize: '10',
                fontColor: 'red',
                textAlign: 'left',
                // underline
                decoration: '',
                // italic
                fontStyle: '',
                // 900
                fontWeight: '',
                letterSpacing: '2',
                lineHeight: '40',
                fontFamily: ''
              }
          },
        ]
        },
        {id: 2, itemImg: '', backColor: 'green', backImage: '../../../assets/image/1.jpg', backType: 1, srtFlag: true,fontTextarea: 'nihao2',
          list: [
            {
              title: '你可以拖着我，按照自己的意愿调整大小。1',
              x: 0, y: 0, w: 200, h: 200,
              angle: 0,
              rotatable: false,
              lockAspectRatio: true,
              z: 1,
            },
            {
              title: '你可以拖着我，按照自己的意愿调整大小。1',
              x: 0, y: 0, w: 200, h: 200,
              angle: 0,
              rotatable: false,
              lockAspectRatio: true,
              z: 2,
            },
            {
              title: '你可以拖着我，按照自己的意愿调整大小。2',
              x: 210, y: 0, w: 200, h: 200,
              angle: 0,
              rotatable: false,
              lockAspectRatio: true,
              z: 3,
            },
            {
              title: '你可以拖着我，按照自己的意愿调整大小。3',
              x: 420, y: 0, w: 200, h: 200,
              angle: 0,
              rotatable: false,
              lockAspectRatio: false,
              z: 2,
              stroke:{
                color: '#ffffff',
                thickness: 10
              }
            },
          ]
        }
      ],
      activeCard: {},
      token: null
    };
  },
  mutations: {
    updateCount(state, data) {
      state.list = data
    },
    updateActiveCard(state, data){
      state.activeCard = data
    },
    setToken(state, data) {
      state.token = data
    },
    setHttps(state, data) {
      
    }
  },
  actions: {
    
  },
  getters: {
   
  }
}
