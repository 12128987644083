import { createRouter, createWebHashHistory } from 'vue-router';
export const constantRoutes = [
    {
		path: '/login',
		name: 'login',
		meta: {
			title: '登录'
		},
		component: () => import('@/views/login/login.vue'),
		beforeEnter: (to, from, next) => {
			if(localStorage.getItem('userCode')){
				const data = JSON.parse(localStorage.getItem('userCode'))
				document.querySelector('link[rel="icon"]').href = data.logo
				document.title = data.name;
			}else{
				document.querySelector('link[rel="icon"]').href = 'https://ai.shooknet.com/resource/2024-07-26/91777a23de5c717707c4e1edb5c732719e7d3f2bccaff4e18d3ed10633b55df7.png'
				document.title = 'AI数字人';
			}
			
			next();
		}
	},
	{
		path: '/jumpPage',
		name: 'jumpPage',
		meta: {
			title: '免登录'
		},
		component: () => import('@/views/login/jumpPage.vue'),
		beforeEnter: (to, from, next) => {
			if(localStorage.getItem('userCode')){
				const data = JSON.parse(localStorage.getItem('userCode'))
				document.querySelector('link[rel="icon"]').href = data.logo
				document.title = data.name;
			}else{
				document.querySelector('link[rel="icon"]').href = 'https://ai.shooknet.com/resource/2024-07-26/91777a23de5c717707c4e1edb5c732719e7d3f2bccaff4e18d3ed10633b55df7.png'
				document.title = 'AI数字人';
			}
			
			next();
		}
	},
    {
		path: '/',
		name: 'index',
		meta: {
			title: '首页'
		},
		component: () => import('@/views/home/index.vue'),
		beforeEnter: (to, from, next) => {
			if(localStorage.getItem('userCode')){
				const data = JSON.parse(localStorage.getItem('userCode'))
				document.querySelector('link[rel="icon"]').href = data.logo
				document.title = data.name;
			}else{
				document.querySelector('link[rel="icon"]').href = 'https://ai.shooknet.com/resource/2024-07-26/91777a23de5c717707c4e1edb5c732719e7d3f2bccaff4e18d3ed10633b55df7.png'
				document.title = 'AI数字人';
			}
			
			next();
		},
		// 使用 children 数组来定义子路由  
		children: [  
			{  
				path: '/',  
				name: 'index',  
				meta: {  
					title: '首页'  
				},  
				component: () => import('@/views/home/components/listPage.vue'),
			},  
			{  
				path: 'templates',  
				name: 'templates',  
				meta: {  
					title: '视频模板'  
				},  
				component: () => import('@/views/home/components/templates.vue'),
				children: [{
					path: 'myTemplate',  
					name: 'myTemplate',  
					meta: {  
						title: '我的模板'  
					},  
					component: () => import('@/views/home/components/myTemplate.vue'),
				},
				{
					path: 'templateAll',  
					name: 'templateAll',  
					meta: {  
						title: '模板'  
					},  
					component: () => import('@/views/home/components/templateAll.vue'),
				}]
			},  
			{  
				path: 'creation',  
				name: 'creation',  
				meta: {  
					title: '我的创作'  
				},  
				component: () => import('@/views/home/components/myCreation.vue')  
			},  
			{  
				path: 'videoList',  
				name: 'videoList',  
				meta: {  
					title: '我的视频'  
				},  
				component: () => import('@/views/home/components/myResult.vue')  
			}, 
			// {  
			// 	path: 'createai',  
			// 	name: 'createai',  
			// 	meta: {  
			// 		title: '创建新数字人'  
			// 	},  
			// 	component: () => import('@/views/home/components/create.vue')  
			// },
			{  
				path: 'createai',  
				name: 'createai',  
				meta: {  
					title: '创建新数字人'  
				},  
				component: () => import('@/views/home/components/createHuman.vue')  
			},  
			
		]
	},
	{
		path: '/canvasPage',
		name: 'canvasPage',
		meta: {
			title: '创作'
		},
		component: () => import('@/views/canvasPage/index.vue'),
		beforeEnter: (to, from, next) => {
			if(localStorage.getItem('userCode')){
				const data = JSON.parse(localStorage.getItem('userCode'))
				document.querySelector('link[rel="icon"]').href = data.logo
				document.title = data.name;
			}else{
				document.querySelector('link[rel="icon"]').href = 'https://ai.shooknet.com/resource/2024-07-26/91777a23de5c717707c4e1edb5c732719e7d3f2bccaff4e18d3ed10633b55df7.png'
				document.title = 'AI数字人';
			}
			
			next();
		}
	},
	
	{  
		path: '/member',  
		name: 'member',  
		meta: {  
			title: '个人中心'  
		},  
		component: () => import('@/views/Member/index.vue'),  
		beforeEnter: (to, from, next) => {
			if(localStorage.getItem('userCode')){
				const data = JSON.parse(localStorage.getItem('userCode'))
				document.querySelector('link[rel="icon"]').href = data.logo
				document.title = data.name;
			}else{
				document.querySelector('link[rel="icon"]').href = 'https://ai.shooknet.com/resource/2024-07-26/91777a23de5c717707c4e1edb5c732719e7d3f2bccaff4e18d3ed10633b55df7.png'
				document.title = 'AI数字人';
			}
			
			next();
		},
		// 使用 children 数组来定义子路由  
		children: [  
			{  
				path: 'info',  
				name: 'info',  
				meta: {  
					title: '我的信息'  
				},  
				component: () => import('@/views/Member/components/UserInfo.vue')  
			},  
			{  
				path: 'security',  
				name: 'security',  
				meta: {  
					title: '账号安全'  
				},  
				component: () => import('@/views/Member/components/UserSecurity.vue')  
			},  
			{  
				path: 'duration',  
				name: 'duration',  
				meta: {  
					title: '我的时长'  
				},  
				component: () => import('@/views/Member/components/Duration.vue')  
			},
			{
				path: 'Audit',  
				name: 'Audit',  
				meta: {  
					title: '审核列表'  
				},  
				component: () => import('@/views/Member/components/Audit list.vue')  
			}    
		]  
	}  
];
const router = createRouter({
	history: createWebHashHistory(),
	routes: constantRoutes,
});
export function setupRouter(app) {
	app.use(router);
	return router;
}

export default router;