import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router/index.js'
// import store from './store'
import ElpxentPlus from 'element-plus'
// import "./assets/style/fontStyle.css"
import { ElLoading, ElMessage } from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElpxentPlusIconsVue from '@element-plus/icons-vue'
import ColorPicker from 'colorpicker-v3'
import './assets/style/colorStyle.css'
import VueDragResizeRotate from "@gausszhou/vue3-drag-resize-rotate";
// import "/node_modules/.pnpm/@gausszhou+vue3-drag-resize-rotate@3.0.2_vue@3.4.31/node_modules/@gausszhou/vue3-drag-resize-rotate/lib/bundle.esm.css";
import "@/assets/style/draggable.css";
import { MouseMenuDirective, MouseMenuCtx } from '/node_modules/.pnpm/@howdyjs+mouse-menu@2.1.3_vue@3.4.31/node_modules/@howdyjs/mouse-menu';
import store from './store'
import domToImage from 'dom-to-image';
import axios from 'axios'
import "amfe-flexible/index.js";
import {
	createPinia
} from 'pinia'
const pinia = createPinia();
const app = createApp(App)
app.config.globalProperties.$http =  axios
app.directive('mouse-menu', MouseMenuDirective)
for (const [key, component] of Object.entries(ElpxentPlusIconsVue)) {
	app.component(key, component)
}
const myGlobalMethod = (val) => {
	console.log('/??????????????????????????');
}
app.provide('$myGlobalMethod', '99999')
app.use(ElpxentPlus).use(store).use(router).use(ColorPicker).use(VueDragResizeRotate).use(domToImage).use(pinia).use(ElLoading).use(ElMessage)

// if ('serviceWorker' in navigator) {
// 	window.addEventListener('load', () => {
// 		navigator.serviceWorker
// 			.register('/serviceWorker.js')
// 			.then(registration => {
// 				console.log('Service Worker 注册成功:--------------------', registration);
// 			})
// 			.catch(error => {
// 				console.log('Service Worker 注册失败: ---------------------', error);
// 			})
// 	})
// }

app.mount('#app')